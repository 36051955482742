import React, { useEffect, useReducer } from 'react';
// import logo from './logo.svg';
import './styles/App.css';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import Header from './components/Header';
import AdminPage from './components/AdminPage';
import DashboardPage from './components/DashboardPage';
import HomePage from './components/HomePage';
import LoanPage from './components/LoanPage';
import LoginPage from './components/LoginPage';
import reducer from './reducer/index';
import { LoanSummaryContext, AppDisplayContext } from './context/index';
import { appRoutes } from './config/AppConstants';
import {
  GetLatestLoanSummary,
  GetTotalLoanCount,
  GetAllLoanSummary,
} from './utils/Api';

const {
  DASHBOARD_PATH,
  HOME_PATH,
  LOAN_PATH,
  ADMIN_PATH,
  LOGIN_PATH,
} = appRoutes;

const STATUS_SUCCESS = 200;

const initialLoanSummaryState = {
  globalTotalLoanCount: 0,
  globalLatestLoanSummary: [],
  globalAllLoanSummary: [],
};

const initialAppDisplayState = {
  selectedLanguage: 'ta',
};

export default function App() {
  const [loanSummaryState, loanSummaryDispatch] = useReducer(
    reducer,
    initialLoanSummaryState
  );
  const [appDisplayState, appDisplayDispatch] = useReducer(
    reducer,
    initialAppDisplayState
  );

  useEffect(() => {
    const fetchAllData = async () => {
      // Fetch latest 100 loan items that are bank unassigned
      const getLatestLoanSummaryResponse = await GetLatestLoanSummary();

      if (getLatestLoanSummaryResponse.status !== STATUS_SUCCESS) return;

      // Fetch all loan items list
      const getAllLoanSummaryResponse = await GetAllLoanSummary();

      // Fetch total loan items count,
      //const getTotalLoanCountResponse = await GetTotalLoanCount();

      if (getAllLoanSummaryResponse.status !== STATUS_SUCCESS) return;
      const totalLoanSummaryCount = getAllLoanSummaryResponse.data.length;

      // store it in global state

      loanSummaryDispatch({
        type: 'LOAD_INITIAL_STATE_FROM_DB',
        payload: {
          dbAllLoanSummary: getAllLoanSummaryResponse.data,
          dbTotalLoanCount: totalLoanSummaryCount,
          dbLatestLoanSummary: getLatestLoanSummaryResponse.data,
        },
      });
      // use that state array everywhere
    };
    fetchAllData();
  }, []);

  return (
    <LoanSummaryContext.Provider
      value={{ loanSummaryState, loanSummaryDispatch }}
    >
      <AppDisplayContext.Provider
        value={{ appDisplayState, appDisplayDispatch }}
      >
        <div className="App">
          <Router>
            <div id="header">
              <Header />
            </div>
            <div id="content">
              <Route exact path="/">
                <Redirect to={HOME_PATH} />
              </Route>
              <Route exact path={HOME_PATH}>
                <HomePage />
              </Route>
              <Route path={LOAN_PATH}>
                <LoanPage />
              </Route>
              <Route path={DASHBOARD_PATH}>
                <DashboardPage />
              </Route>
              <Route path={ADMIN_PATH}>
                <AdminPage />
              </Route>
              <Route path={LOGIN_PATH}>
                <LoginPage />
              </Route>
            </div>
          </Router>
        </div>
      </AppDisplayContext.Provider>
    </LoanSummaryContext.Provider>
  );
}

/*

           <Router>
        <Route exact path={DASHBOARD_PATH}>
          <Dashboard
            globalJobTrackingStages={globalJobTrackingStages}
            dashboardDisplayWipList={dashboardDisplayWipList}
            showBorder={this.state.showBorder}
          />
        </Route>
        <Route path={SCANNER_CONFIG_PATH}>
          <ScannerConfigurationPage
            startScanner={this.startScanner}
            stopScanner={this.stopScanner}
            startAllScanners={this.startAllScanners}
            stopAllScanners={this.stopAllScanners}
            globalScannerDetails={globalScannerDetails}
            globalProcessDetails={globalProcessDetails}
            addNewScannerToDB={this.addNewScannerToDB}
            updateScannerDetailsToDB={this.updateScannerDetailsToDB}
            deleteScannerDetailsToDB={this.deleteScannerDetailsToDB}
          />
        </Route>
        <Route path={PROCESS_CONFIG_PATH}>
          <ProcessConfigurationPage
            globalProcessDetails={globalProcessDetails}
            addNewProcessToDB={this.addNewProcessToDB}
            updateProcessDetailsToDB={this.updateProcessDetailsToDB}
            deleteProcessDetailsToDB={this.deleteProcessDetailsToDB}
            addDefaultProcessDetailsToDB={this.addDefaultProcessDetailsToDB}
          />
        </Route>
        <Route exact path={INDUCTION_PATH}>
          <InductionPage
            globalTagDetails={globalTagDetails}
            globalWipItemDetails={globalWipItemDetails}
            addTagToDB={this.addTagToDB}
            updateTagToDB={this.updateTagToDB}
            globalScannerDetails={globalScannerDetails}
            globalJobPackDetails={globalJobPackDetails}
            addNewTagToDB={this.addNewTagToDB}
            updateTagDetailsToDB={this.updateTagDetailsToDB}
            addToGlobalTagDetails={this.addToGlobalTagDetails}
            addNewJobPackToDB={this.addNewJobPackToDB}
            updateJobPackDetailsToDB={this.updateJobPackDetailsToDB}
            deleteJobPackDetailsToDB={this.deleteJobPackDetailsToDB}
            deleteTagDetailsToDB={this.deleteTagDetailsToDB}
          />
        </Route>
        <Route exact path={SIMULATION_PATH}>
          <SimulationPage
            addTagToDB={this.addTagToDB}
            updateTagToDB={this.updateTagToDB}
            completeJobAndUpdateToDB={this.completeJobAndUpdateToDB}
            globalWipItemDetails={globalWipItemDetails}
            globalScannerDetails={globalScannerDetails}
          />
        </Route> */
