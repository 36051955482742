export const addLoanBillDetails = {
  display: 'flex',
  flexDirection: 'column',
};
export const addLoanForm = {
  display: 'flex',
  flexDirection: 'column',
};
export const addLoanAddress = {
  verticalAlign: 'top',
};
export const addLoanWebCam = {
  width: '300px',
  height: '300px',
};
