const appScannerConstants = {
  SCANNER_START_REQUEST: 'ScannerStart',
  SCANNER_STOP_REQUEST: 'ScannerStop',
  SCANNER_START_SUCCESS: 'ScannerStartSuccess',
  SCANNER_STOP_SUCCESS: 'ScannerStopSuccess',
};

const appDefaultConstants = {
  READ_FROM_DB: 'ReadFromDB',
  READ_FROM_HISTORY_TABLE: 'ReadFromHistoryTable',
  ERROR_MSG: 'ErrorMessage',
  WIP_TRACKER_MSG: 'WipTracking',
  WIP_TRACKER_SUMMARY_FROM_DB: 'WipTrackingMsgFromDB',
  // WIP_TRACKER_MSG: 'WipTrakerSummaryMessage',
  // WIP_TRACKER_SUMMARY_FROM_DB: 'WipTrackerDatabaseSummaryMessage',
  WIP_TRACKER_SUMMARY_HISTORY_UPDATE: 'WipTrackingMsgHistoryUpdate',
  // Scanner
  ADD_SCANNER_DETAILS_TO_DB: 'AddScannerDetailsToDB',
  DELETE_SCANNER_DETAILS_TO_DB: 'DeleteScannerDetailsToDB',
  UPDATE_SCANNER_DETAILS_TO_DB: 'UpdateScannerDetailsToDB',
  // Tag
  ADD_TAG_DETAILS_TO_DB: 'AddTagDetailsToDB',
  DELETE_TAG_DETAILS_TO_DB: 'DeleteTagDetailsToDB',
  UPDATE_TAG_DETAILS_TO_DB: 'UpdateTagDetailsToDB',
  // Process
  ADD_PROCESS_DETAILS_TO_DB: 'AddProcessDetailsToDB',
  DELETE_PROCESS_DETAILS_TO_DB: 'DeleteProcessDetailsToDB',
  UPDATE_PROCESS_DETAILS_TO_DB: 'UpdateProcessDetailsToDB',
  // JobPack
  ADD_JOBPACK_DETAILS_TO_DB: 'AddJobPackDetailsToDB',
  DELETE_JOBPACK_DETAILS_TO_DB: 'DeleteJobPackDetailsToDB',
  UPDATE_JOBPACK_DETAILS_TO_DB: 'UpdateJobPackDetailsToDB',
};

const appRoutes = {
  HOME_PATH: '/home',
  LOAN_PATH: '/loan/add',
  ADMIN_PATH: '/admin',
  DASHBOARD_PATH: '/dashboard',
  LOGIN_PATH: '/login',
};

const appLoanRoutes = {
  ADD_PATH: '/loan/add',
  ASSIGN_BANK_PATH: '/loan/assignbank',
  SEARCH_PATH: '/loan/search',
  RENEW_PATH: '/loan/renew',
  PAYINTEREST_PATH: '/loan/payinterest',
  RELEASE_PATH: '/loan/release',
};
const appIdConstants = {
  TAG_ID: 'tagId',
  SCANNER_ID: 'scannerId',
  PROCESS_ID: 'processId',
  JOBPACK_ID: 'jobPackId',
};

const scannerStatusConstants = {
  startColor: '#00838f',
  stopColor: 'red',
};
/** SIMULATION FUNCTIONALITIES */
const appSimulationConstants = {
  ADD_TAG_TO_DB: 'AddTag',
  UPDATE_TAG_TO_DB: 'UpdateTagStatus',
};
/** SIMULATION FUNCTIONALITIES */

export {
  appScannerConstants,
  appRoutes,
  appLoanRoutes,
  appSimulationConstants,
  appDefaultConstants,
  appIdConstants,
  scannerStatusConstants,
};
