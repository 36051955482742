import axios from 'axios';
import {
  getLatestLoanSummaryUrl,
  getLoanSummaryCountUrl,
  getAllLoanSummaryUrl,
  releaseLoanDetailsUrl,
} from '../config/ApiRoutes';

export const GetLatestLoanSummary = async () => {
  console.info('API - GetLatestLoanSummary');
  const latestLoanSummaryResponse = await axios.get(getLatestLoanSummaryUrl);
  console.info(latestLoanSummaryResponse);
  return latestLoanSummaryResponse;
};

export const GetTotalLoanCount = async () => {
  console.info('API - GetCount');
  const loanCountResponse = await axios.get(getLoanSummaryCountUrl);
  console.info(loanCountResponse);
  return loanCountResponse;
};

export const GetAllLoanSummary = async () => {
  console.info('API - GetAllLoanSummary');
  const allLoanSummaryResponse = await axios.get(getAllLoanSummaryUrl);
  console.info(allLoanSummaryResponse);
  return allLoanSummaryResponse;
};

export const GetLoanDetailsById = async () => {
  console.info('API - GetLoanDetailsById');
  const allLoanSummaryResponse = await axios.get(getAllLoanSummaryUrl);
  console.info(allLoanSummaryResponse);
  return allLoanSummaryResponse;
};

export const ReleaseLoanById = async (loanId, loanReleaseDate) => {
  console.info('API - ReleaseLoanById');
  const loanDetails = {
    loanBillId: loanId,
    releaseAmount: 0,
    releaseDate: loanReleaseDate,
  };
  const releaseLoanDetails = await axios.post(
    releaseLoanDetailsUrl,
    loanDetails
  );
  console.info(releaseLoanDetails);
  return releaseLoanDetails;
};
