/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useContext, useCallback } from 'react';
import MaterialTable, { MTableBody } from 'material-table';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import { AppDisplayContext, LoanSummaryContext } from '../../context/index';
import Select from '@material-ui/core/Select';

import PropTypes from 'prop-types';

const MAX_LOAN_AMOUNT_GOLD = 1000;
const MAX_LOAN_AMOUNT_SILVER = 500;
const generateTableActions = () => {
  const actions = [];

  return actions;
};

function AddLoanItemsTable(parentProps) {
  const { t, i18n } = useTranslation();

  const tempAppDisplayContext = useContext(AppDisplayContext);
  const { appDisplayState, appDisplayDispatch } = tempAppDisplayContext;
  const { selectedLanguage } = appDisplayState;
  // const { currentTableData, setCurrentTableData } = useState([]);

  const {
    currentLoanItemsData,
    addToCurrenLoanItemsData,
    addToCurrentLoanSumData,
  } = parentProps;
  // const [displayData, setDisplayData] = useState([]);
  const [displayActions, setDisplayActions] = useState([]);
  const [displayColumns, setDisplayColumns] = useState([]);

  const generateTableHeader = () => {
    console.log('generateTableHeader');
    const columns = [];
    const numItems =
      currentLoanItemsData && currentLoanItemsData.length > 0
        ? currentLoanItemsData.length
        : 0;
    let maxLoanValue = 0;
    let maxLoanValueText = ``;
    let isError = false;
    const itemTypeLookup = {};

    itemTypeLookup.ring = t('ring');
    itemTypeLookup.stoneRing = t('stoneRing'); // new
    itemTypeLookup.roundring = t('roundring'); // new
    itemTypeLookup.none = '-----------------'; // new

    itemTypeLookup.bracelet = t('bracelet'); // new
    itemTypeLookup.bangle = t('bangle');
    itemTypeLookup.none1 = '-----------------'; // new

    itemTypeLookup.earringStud = t('earringStud');

    itemTypeLookup.earringDrops = t('earringDrops');

    itemTypeLookup.earringStoneStud = t('earringStoneStud'); // new
    itemTypeLookup.earringDropsJimiki = t('earringDropsJimiki'); // new
    itemTypeLookup.earringMaatal = t('earringMaatal'); // new
    itemTypeLookup.earringValayam = t('earringValayam'); // new

    itemTypeLookup.none2 = '-----------------'; // new
    itemTypeLookup.chain = t('chain');

    itemTypeLookup.mangalsutra = t('mangalsutra'); // new
    itemTypeLookup.mangalsutraKundu = t('mangalsutraKundu'); // new
    itemTypeLookup.mangalsutraNaanal = t('mangalsutraNaanal'); // new
    itemTypeLookup.mangalsutraSaradu = t('mangalsutraSaradu'); // new
    itemTypeLookup.mangalsutraMangaiKaasu = t('mangalsutraMangaiKaasu'); // new

    itemTypeLookup.mangalsutraKaasu = t('mangalsutraKaasu');

    itemTypeLookup.mangalsutraOther = t('mangalsutraOther');
    itemTypeLookup.none3 = '-----------------'; // new

    itemTypeLookup.coin = t('coin');
    itemTypeLookup.kaasu = t('kaasu');
    itemTypeLookup.none4 = '-----------------'; // new

    itemTypeLookup.nosering = t('nosering');
    itemTypeLookup.anklet = t('anklet');
    itemTypeLookup.hipthread = t('hipthread'); // new

    const itemMetalLookup = {};
    itemMetalLookup.gold = t('gold');
    itemMetalLookup.silver = t('silver');
    itemMetalLookup.diamond = t('diamond');

    const interestRateLookup = {};
    interestRateLookup[1] = 1;
    interestRateLookup[2] = 2;
    interestRateLookup[3] = 3;
    interestRateLookup[4] = 4;
    interestRateLookup[5] = 5;

    columns.push({
      title: 'Id',
      field: 'id',
      editable: 'never',
      initialEditValue: numItems + 1,
    });
    columns.push({
      title: t('itemType'),
      field: 'itemType',
      lookup: itemTypeLookup,
      initialEditValue: 'ring',
    });

    columns.push({
      title: t('itemMetal'),
      field: 'itemMetal',
      lookup: itemMetalLookup,
      initialEditValue: 'gold',
    });
    columns.push({ title: t('quantity'), field: 'quantity', type: 'numeric' });
    columns.push({
      title: t('weight'),
      field: 'weight',
      // initialEditValue: 0,
      type: 'numeric',
      editComponent: (props) => {
        const { value, rowData } = props;
        console.log(value);
        console.log(rowData);
        if (value > 0) {
          console.log('calculating max loan amount');

          const { itemMetal, weight } = rowData;

          const MAX_LOAN_AMOUNT =
            itemMetal === 'gold'
              ? MAX_LOAN_AMOUNT_GOLD
              : MAX_LOAN_AMOUNT_SILVER;
          const calculatedMaxLoanAmt = MAX_LOAN_AMOUNT * weight;
          console.log('calculatedMaxLoanAmt');

          console.log(calculatedMaxLoanAmt);
          // setMaxLoanValue(calculatedMaxLoanAmt);
          // setMaxLoanValueText(`Max Loan : ${calculatedMaxLoanAmt}`);
          maxLoanValue = calculatedMaxLoanAmt;
          maxLoanValueText = `Max Loan : ${calculatedMaxLoanAmt}`;
        }
        return (
          <TextField
            type="text"
            value={value > 0 ? value : ''}
            // placeholder="0"
            onChange={(e) => {
              props.onChange(e.target.value);
            }}
            // onFocus={e => {
            //   console.log('on focus value - ', value);
            //   if (value === 0) props.value = '';
            // }}
          />
        );
      },
    });

    // columns.push({ title: 'Interest Rate', field: 'interestRate', lookup: interestRateLookup, initialEditValue: 3 });
    // columns.push({ title: 'Max Loan Value', field: 'maxItemValue', initialEditValue: maxLoanValue, type: 'numeric' });
    columns.push({
      title: t('loanAmount'),
      field: 'loanAmount',
      type: 'numeric',
      editComponent: (props1) => {
        const { value, rowData } = props1;
        console.log(value);
        console.log(rowData);
        if (value > maxLoanValue) {
          isError = true;
        } else isError = false;
        return (
          <TextField
            error={isError}
            id="outlined-error-helper-text"
            value={value}
            helperText={maxLoanValueText}
            variant="outlined"
            onChange={(e) => {
              props1.onChange(e.target.value);
            }}
          />
        );
      },
    });

    return columns;
  };
  //   [selectedLanguage],
  // );

  useEffect(() => {
    // const numItems = currentLoanItemsData && currentLoanItemsData.length > 0 ? currentLoanItemsData.length : 0;

    setDisplayActions(generateTableActions());
    setDisplayColumns(generateTableHeader());
    // setDisplayData(currentLoanItemsData);
  }, [selectedLanguage]);

  const addData = async (receivedNewData) => {
    const newData = { ...receivedNewData };
    console.info(newData);
    newData.weight = parseFloat(newData.weight);
    newData.loanAmount = parseFloat(newData.loanAmount);

    const currentDisplayData = [...currentLoanItemsData];

    currentDisplayData.push(newData);
    // const currentTableDisplayData = [...currentTableData];
    // currentTableDisplayData.push(newData);
    console.info('currentDisplayData');

    console.info(currentDisplayData);
    addToCurrenLoanItemsData(currentDisplayData);
  };

  const updateData = async (receivedNewData, receivedOldData) => {
    console.info(receivedNewData);
    console.info(receivedOldData);
    const newData = { ...receivedNewData };
    console.info(newData);
    newData.weight = parseFloat(newData.weight);
    newData.loanAmount = parseFloat(newData.loanAmount);

    addToCurrenLoanItemsData(
      currentLoanItemsData.map((loanItem) => {
        if (loanItem.id === newData.id) return newData;
        return loanItem;
      })
    );
    console.info(currentLoanItemsData);
  };

  const deleteData = async () => {};

  const editableActions = {
    onRowAdd: (newData) => addData(newData),
    onRowUpdate: (newData, oldData) => updateData(newData, oldData),
    onRowDelete: (oldData) => deleteData(oldData),
  };

  const TableFooter = () => {
    console.log('TableFooter');
    console.log(currentLoanItemsData);
    const [sumTotalLoanAmount, setSumTotalLoanAmount] = useState(0);
    const [totalLoanAmount, setTotalLoanAmount] = useState(0);
    const [loanInterestRate, setLoanInterestRate] = useState(2);
    const [billRateAmount, setBillRateAmount] = useState(0);
    const [loanInterestAmount, setLoanInterestAmount] = useState(0);

    useEffect(() => {
      if (currentLoanItemsData.length > 0) {
        const tempTotalAmount = currentLoanItemsData.reduce(function (
          total,
          loanItem
        ) {
          return total + loanItem.loanAmount;
        },
        0);
        console.log(tempTotalAmount);

        setTotalLoanAmount(tempTotalAmount);
      }
    }, [currentLoanItemsData]);

    useEffect(() => {
      let tempLoanInterestRate = 2;
      const tempLoanBillRate = 1;
      if (totalLoanAmount <= 10000) tempLoanInterestRate = 3;
      else tempLoanInterestRate = 2;

      const tempLoanInterestAmount =
        totalLoanAmount * (tempLoanInterestRate / 100);
      const tempBillRateAmount = totalLoanAmount * (tempLoanBillRate / 100);
      const totalInterestAmount = tempLoanInterestAmount + tempBillRateAmount;
      const tempSumTotalLoanAmount = totalLoanAmount - totalInterestAmount;

      if (tempLoanInterestAmount > 0)
        setLoanInterestAmount(tempLoanInterestAmount);
      if (totalLoanAmount > 0) setSumTotalLoanAmount(tempSumTotalLoanAmount);
      if (tempBillRateAmount > 0) setBillRateAmount(tempBillRateAmount);

      setLoanInterestRate(tempLoanInterestRate);
      // if (tempSumTotalLoanAmount > 0 && tempSumTotalLoanAmount !== totalLoanAmount) {
      //   // update summary total data
      //   const tempCurrentLoanSumData = {
      //     sumTotalLoanAmount: tempSumTotalLoanAmount,
      //     // totalLoanAmount,
      //     loanInterestRate: tempLoanInterestRate,
      //     billRateAmount: tempBillRateAmount,
      //     loanInterestAmount: tempLoanInterestAmount,
      //   };
      //   addToCurrentLoanSumData(tempCurrentLoanSumData);
      // }
      // setLoanBillRate(tempLoanBillRate);
    }, [totalLoanAmount]);

    return (
      <tbody>
        <tr>
          <td colSpan="5">{t('loanTotal')}</td>
          <td colSpan="5">
            <input
              type="text"
              value={totalLoanAmount}
              onChange={(e) => {
                console.log(e.target.value);
                setTotalLoanAmount(e.target.value);
              }}
            />
          </td>
        </tr>
        <tr>
          <td colSpan="5">{t('interestRate')}</td>
          <td colSpan="5">
            <input
              type="text"
              value={loanInterestRate}
              onChange={(e) => {
                console.log(e.target.value);
                setLoanInterestRate(e.target.value);
              }}
            />
          </td>
        </tr>

        <tr>
          <td colSpan="5">{t('interestAmount')}</td>

          <td colSpan="5">
            <input
              type="text"
              value={loanInterestAmount}
              onChange={(e) => {
                console.log(e.target.value);
                setLoanInterestAmount(e.target.value);
              }}
            />
          </td>
        </tr>
        <tr>
          <td colSpan="5">{t('BillAmount')}</td>

          <td colSpan="5">
            <input
              type="text"
              value={billRateAmount}
              onChange={(e) => {
                console.log(e.target.value);
                setBillRateAmount(e.target.value);
              }}
            />
          </td>
        </tr>
        <tr>
          <td colSpan="5">{t('sumTotalLoanAmount')}</td>
          <td colSpan="5">
            <input
              type="text"
              value={sumTotalLoanAmount}
              onChange={(e) => {
                console.log(e.target.value);
                setSumTotalLoanAmount(e.target.value);
              }}
            />
          </td>
        </tr>
      </tbody>
    );
  };

  console.info('Render - Add loan items details table');
  return (
    <div>
      <MaterialTable
        title=""
        columns={generateTableHeader()}
        data={currentLoanItemsData}
        actions={displayActions}
        editable={editableActions}
        options={{
          actionsColumnIndex: -1,
          pageSize: 5,
        }}
        localization={{
          body: {
            editRow: {
              deleteText: 'Are you sure want to delete?',
            },
          },
        }}
        components={{
          Body: (props) => (
            <>
              <MTableBody {...props} />
              <TableFooter />
            </>
          ),
        }}
      />
    </div>
  );
}

export default AddLoanItemsTable;

AddLoanItemsTable.defaultProps = {
  addToCurrenLoanItemsData: () => console.info('add to current loan items'),
  currentLoanItemsData: [],
};

AddLoanItemsTable.propTypes = {
  currentLoanItemsData: PropTypes.shape({}),
  // eslint-disable-next-line react/no-unused-prop-types
  addToCurrenLoanItemsData: PropTypes.func,
};
