import React, { useRef, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  dividerCmpnt,
  contentTop10Margin,
  pageTitle,
  content10Margin,
  sectionTitle,
} from '../../styles/LoanPageStyle';
import { FormLabelInputControl, CurrentDateComponent } from './FormControls';
import axios from 'axios';
import { LoanSummaryContext } from '../../context/index';
import { ReleaseLoanById } from '../../utils/Api';
import { calendarFormat } from 'moment';
ReleaseLoan.propTypes = {};

function ReleaseLoan(props) {
  const currentDateRefState = useRef(null);
  const receiptNumRefState = useRef(0);
  const [currentLoanDetails, setCurrentLoanDetails] = useState({});
  const [defaultLoanBillId, setDefaultLoanBillId] = useState('');

  const { t } = useTranslation();
  const tempLoanSummaryContext = useContext(LoanSummaryContext);

  const { loanSummaryState, loanSummaryDispatch } = tempLoanSummaryContext;
  const {
    globalLatestLoanSummary,
    globalTotalLoanCount,
    globalAllLoanSummary,
  } = loanSummaryState;

  //   let currentLoanDetails = {};

  const clearFormDetails = async () => {
    console.log('clearFormDetails');
    setCurrentLoanDetails({});
    // setDefaultLoanBillId('');
    let element = document.getElementById('receiptNumber');
    console.log(element);
    element.value = '';
    console.log(receiptNumRefState);
    //receiptNumRefState.current.reset();

    // receiptNumRefState.reset();
    //receiptNumRefState.current = 1;
    //receiptNumRefState.current = 0;
  };
  const doReleaseLoan = async () => {
    console.log('doReleaseLoan');
    const loanId = +receiptNumRefState.current;
    const loanReleaseDate = currentDateRefState.current;
    const releaseLoanDetails = await ReleaseLoanById(loanId, loanReleaseDate);
    console.log(releaseLoanDetails);

    //Update Latest Loan Summary and All Loan Summary
    const updatedLatestLoanSummary = globalLatestLoanSummary.filter(
      (loanSummary) => loanSummary.loanBillId !== loanId
    );
    //const tempArr = [];
    //console.log()
    const updatedAllLoanSummary = globalAllLoanSummary.filter(
      (loanSummary) => loanSummary.loanBillId !== loanId
    );
    console.log('updatedAllLoanSummary');

    console.log(updatedAllLoanSummary);
    loanSummaryDispatch({
      type: 'LOAD_INITIAL_STATE_FROM_DB',
      payload: {
        dbAllLoanSummary: updatedAllLoanSummary,
        dbTotalLoanCount: globalTotalLoanCount - 1,

        dbLatestLoanSummary: updatedLatestLoanSummary,
      },
    });
    alert('Release done successfully');
    clearFormDetails();
  };

  const getLoanDetailsById = () => {
    console.log('getLoanDetailsById');
    console.log(receiptNumRefState.current);

    const inputLoanId = +receiptNumRefState.current;
    //console.log(5652 === inputLoanId);

    const currentLoanList = globalAllLoanSummary.filter(
      (loanSummary) => loanSummary.loanBillId === inputLoanId
    );
    if (currentLoanList.length > 0) setCurrentLoanDetails(currentLoanList[0]);
    console.log(currentLoanDetails);
  };
  return (
    <div id="release-loan" style={contentTop10Margin}>
      <h3 style={pageTitle}>Add Loan</h3>
      <div
        id="release-loan-content"
        style={content10Margin}
        className="container"
      >
        <div id="date-content" className="row justify-content-end">
          <label className="col-lg-auto col-sm col-md"> Date:</label>
          <CurrentDateComponent currentDateRef={currentDateRefState} />
        </div>
        <div id="form-content" style={content10Margin}>
          <h5 style={sectionTitle}>{t('billNumberDetails')}</h5>
          <div id="bill-id-details" className="row justify-content-start">
            <FormLabelInputControl
              name="receiptNumber"
              labelText={t('receiptNumber')}
              inputref={receiptNumRefState}
              defaultValue={defaultLoanBillId}
            />
            <input
              type="button"
              value="Search"
              style={{ marginLeft: 30 }}
              onClick={getLoanDetailsById}
            />
          </div>
          <div
            style={{
              marginTop: 50,
              marginBottom: 50,

              height: 5,
              width: visualViewport.width,
              backgroundColor: 'gray',
            }}
          />
          <div id="form-content" style={content10Margin}>
            <h5 style={sectionTitle}>{t('loanItemDetails')}</h5>

            <div
              id="bill-summary"
              className="row"
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
              }}
            >
              <label>{t('receiptNumber')}</label>
              <label>{currentLoanDetails.loanBillId}</label>
              <label>{t('loanTotal')}</label>
              <label>{currentLoanDetails.loanAmount}</label>
              <label>Loan Date:</label>
              <label>
                {currentLoanDetails.loanDate &&
                  new Date(currentLoanDetails.loanDate).toLocaleString()}
              </label>
              {/* <table>
                <tr>
                  <td>Bill Number:</td>
                  <td>{currentLoanDetails.loanBillId}</td>
                </tr>
              </table> */}
            </div>
          </div>
          <div
            style={{
              marginTop: 50,
              marginBottom: 50,

              height: 5,
              width: visualViewport.width,
              backgroundColor: 'gray',
            }}
          />
          <div id="bill-summary" className="row">
            <div className="col">
              <input type="button" value="Release" onClick={doReleaseLoan} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseLoan;
