import React, { useContext, useCallback, useEffect, useState } from 'react';
import MaterialTable, { MTableBody } from 'material-table';

import { LoanSummaryContext } from '../context/index';
import { FormControlLabel, TextField } from '@material-ui/core';

export default function HomePage() {
  console.info('HomePage');
  const tempLoanSummaryContext = useContext(LoanSummaryContext);

  const { loanSummaryState } = tempLoanSummaryContext;
  const {
    globalLatestLoanSummary,
    globalTotalLoanCount,
    globalAllLoanSummary,
  } = loanSummaryState;

  const [displayLoanSummary, setDisplayLoanSummary] =
    useState(globalAllLoanSummary);

  useEffect(() => {
    setDisplayLoanSummary(globalAllLoanSummary);
  }, [globalAllLoanSummary]);

  const generateTableHeader = useCallback(() => {
    console.info('generateTableHeader - Home');
    const columns = [];

    columns.push({ title: 'Id', field: 'index', editable: 'never' });
    columns.push({ title: 'Bill Id', field: 'loanBillId', editable: 'never' });
    columns.push({ title: 'Book Id', field: 'loanBookId', editable: 'never' });
    columns.push({
      title: 'Book Bill Id',
      field: 'loanBookBillId',
      editable: 'never',
    });
    columns.push({
      title: 'Loan Date',
      field: 'loanDate',
      editable: 'never',
    });

    columns.push({ title: 'Customer Id', field: 'customerId' });
    // columns.push({ title: 'Loan Item', field: '' });

    columns.push({ title: 'Loan Amount', field: 'loanAmount' });
    columns.push({ title: 'Interest Rate', field: 'interestRate' });

    return columns;
  });

  const TableFooter = () => {
    console.log('TableFooter');
    console.log(displayLoanSummary);
    const [sumTotalLoanAmount, setSumTotalLoanAmount] = useState(0);
    const [totalLoanAmount, setTotalLoanAmount] = useState(0);
    const [loanInterestRate, setLoanInterestRate] = useState(2);
    const [billRateAmount, setBillRateAmount] = useState(0);
    const [loanInterestAmount, setLoanInterestAmount] = useState(0);

    useEffect(() => {
      if (displayLoanSummary.length > 0) {
        const tempTotalAmount = displayLoanSummary.reduce(function (
          total,
          loanItem
        ) {
          return total + loanItem.loanAmount;
        },
        0);
        console.log(tempTotalAmount);

        setTotalLoanAmount(tempTotalAmount);
      }
    }, [displayLoanSummary]);

    return (
      <tbody>
        <tr>
          <td colSpan="6">{'loanTotal'}</td>
          <td>{totalLoanAmount}</td>
        </tr>
      </tbody>
    );
  };

  const DataSummary = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'space-between',
        }}
      >
        <div>
          <h5>TOTAL</h5>
        </div>
        <div>
          <select>
            <option>1 Day</option>
            <option>1 Week</option>
            <option>1 Month</option>
            <option>Custom Range</option>
          </select>
        </div>
      </div>
    );
  };
  return (
    <div style={{ margin: 30 }}>
      <MaterialTable
        title=""
        columns={generateTableHeader()}
        data={displayLoanSummary}
        options={{
          actionsColumnIndex: -1,
          pageSize: 20,
        }}
      />
      {/* <ul>
        {globalLatestLoanSummary.map(loanItem => (
          <li key={loanItem.loanBillId}>{loanItem.loanBillId}</li>
        ))}
      </ul> */}
    </div>
  );
}
