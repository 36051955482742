import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from 'react';
import DatePicker from 'react-datepicker';
import { addLoanAddress } from '../../styles/AddLoanPageStyle';
import PropTypes from 'prop-types';

export const FormLabelInputControl = React.memo(
  ({ name = '', labelText, inputref, defaultValue }) => {
    const contentStyle = 'col-lg-auto col-sm col-md';
    const handleChange = (inputValue) => {
      console.log('inputValue');
      console.log(inputValue);

      inputref.current = inputValue;
    };
    if (labelText !== 'Customer Address') {
      return (
        <>
          <label className={contentStyle}>{labelText}</label>
          <input
            id={name}
            className={contentStyle}
            onChange={(e) => handleChange(e.target.value)}
            defaultValue={
              defaultValue != 0 && defaultValue.length > 0 ? defaultValue : ''
            }
            //value={defaultValue}
          />
        </>
      );
    }
    return (
      <>
        <label style={addLoanAddress} className={contentStyle}>
          {labelText}
        </label>
        <textarea
          className={contentStyle}
          onChange={(e) => handleChange(e.target.value)}
        />
      </>
    );
  }
);
FormLabelInputControl.propTypes = {
  labelText: PropTypes.string.isRequired,
};

export const CurrentDateComponent = ({ currentDateRef }) => {
  console.info('CurrentDateComponent');
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      const newDate = new Date();
      setStartDate(newDate);
      currentDateRef.current = newDate;
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <DatePicker
      className="col-lg-auto col-sm col-md"
      dateFormat="dd/MM/yyyy h:mm:ss aa"
      selected={startDate}
      onChange={(date) => setStartDate(date)}
    />
  );
};
