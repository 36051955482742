export const loanPage = {
  display: 'flex',
  height: '100vh',
};

export const loanPageSideBar = {
  flexBasis: '15%',
  background: 'lightgray',
};

export const loanPageSideBarMenuTab = {
  display: 'flex',
  flexDirection: 'column',
};

export const loanPageSideBarSelectedMenu = {
  color: 'blue',
};

export const dividerCmpnt = {
  marginTop: 20,
  marginBottom: 20,

  backgroundColor: 'gray',
  height: 4,
  borderColor: '#000000',
  display: 'block',
};

export const contentTop10Margin = {
  marginTop: 10,
};

export const pageTitle = {
  color: '#00838f',
  textAlign: 'left',
  marginLeft: 10,
};

export const sectionTitle = {
  marginBottom: 20,
};
export const content10Margin = {
  margin: '10px',
};
