/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes, { exact } from 'prop-types';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Webcam from 'react-webcam';
import Divider from '@material-ui/core/Divider';
import AddLoanItemsTable from './AddLoanItemsTable';
import {
  dividerCmpnt,
  contentTop10Margin,
  pageTitle,
  content10Margin,
  sectionTitle,
} from '../../styles/LoanPageStyle';
import { addLoanWebCam, addLoanAddress } from '../../styles/AddLoanPageStyle';
import { AppDisplayContext, LoanSummaryContext } from '../../context/index';
import { addLoanDetailsUrl } from '../../config/ApiRoutes';
import { CurrentDateComponent } from './FormControls';
const DividerComponent = () => <Divider style={dividerCmpnt} />;

const FormLabelDivControl = React.memo(({ labelText }) => {
  return (
    <>
      <label>{labelText}</label>
    </>
  );
});
FormLabelDivControl.propTypes = {
  labelText: PropTypes.string.isRequired,
};

const FormLabelInputControl = React.memo(
  ({ labelText, inputref, defaultValue }) => {
    const contentStyle = 'col-lg-auto col-sm col-md';
    const handleChange = (inputValue) => {
      console.log('inputValue');
      console.log(inputValue);

      inputref.current = inputValue;
    };
    if (labelText !== 'Customer Address') {
      return (
        <>
          <label className={contentStyle}>{labelText}</label>
          <input
            className={contentStyle}
            onChange={(e) => handleChange(e.target.value)}
            defaultValue={defaultValue}
          />
        </>
      );
    }
    return (
      <>
        <label style={addLoanAddress} className={contentStyle}>
          {labelText}
        </label>
        <textarea
          className={contentStyle}
          onChange={(e) => handleChange(e.target.value)}
        />
      </>
    );
  }
);
FormLabelInputControl.propTypes = {
  labelText: PropTypes.string.isRequired,
};

const CameraImageScreenshot = ({ imageSrc, imageAltText }) => {
  console.info(`loading image captured from camera comp - ${imageAltText}`);
  // console.log(imageSrc);
  return (
    imageSrc && <img style={addLoanWebCam} alt="customer face" src={imageSrc} />
  );
};

const CameraComponent = ({
  webCamRef,
  openCamera,
  cameraAltText,
  cameraFacingMode = 'environment',
}) => {
  console.info(`loading  camera comp - ${cameraAltText}`);
  console.log(webCamRef);
  // const videoConstraints = {
  //   video: {
  //     advanced: [('facingMode': { exact: 'environment' })],
  //   },
  // };
  return (
    openCamera && (
      <Webcam
        style={addLoanWebCam}
        audio={false}
        ref={webCamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={{
          advanced: [{ facingMode: { exact: cameraFacingMode } }],
        }}
      />
    )
  );
};

const CustomerCameraAndImageComponent = React.memo(
  ({ openCamera, customerWebcamRef, customerInputRef, cameraFacingMode }) => {
    // console.log('CustomerCameraAndImageComponent');
    const [customerImageSrc, setCustomerImageSrc] = useState(null);
    const { t } = useTranslation();
    // const [cameraFacingMode, setCameraFacingMode] = useState('user');
    const captureCustomer = useCallback(() => {
      const customerImgSrc = customerWebcamRef.current.getScreenshot();
      customerInputRef.current = customerImgSrc;
      setCustomerImageSrc(customerImgSrc);
    }, [customerWebcamRef]);

    // const switchCamera = () => {
    //   console.log('switchCamera');
    //   console.log(customerWebcamRef.current);
    //   let videoConstraints = {};
    //   if (cameraFacingMode === 'user') videoConstraints = { facingMode: { exact: 'environment' } };
    //   else videoConstraints = { facingMode: 'user' };

    //   setCameraFacingMode(videoConstraints);
    //   console.log(customerWebcamRef.current);
    // };
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div id="customer-label-image">
          <FormLabelDivControl labelText={t('customerImage')} />
        </div>
        <div
          id="customer-webcam-controls"
          style={{ display: 'flex', justifyContent: 'space-evenly' }}
        >
          <div>
            <CameraComponent
              openCamera={openCamera}
              webCamRef={customerWebcamRef}
              cameraAltText="Customer Camera"
              cameraFacingMode={cameraFacingMode}
            />
          </div>
          <div>
            <CameraImageScreenshot
              imageSrc={customerImageSrc}
              imageAltText="Customer face"
            />
          </div>
        </div>
        <div>
          <button type="button" onClick={captureCustomer}>
            {t('takeCustomerImage')}
          </button>
          {/* <button type="button" onClick={switchCamera}>
          {t('swichCamera')}
        </button> */}
        </div>
      </div>
    );
  }
);

const ItemCameraAndImageComponent = React.memo(
  ({ openCamera, itemWebcamRef, itemInputRef }) => {
    // console.log('ItemCameraAndImageComponent');
    const [itemImageSrc, setItemImageSrc] = useState(null);
    const { t } = useTranslation();

    const captureItem = useCallback(() => {
      const itemImgSrc = itemWebcamRef.current.getScreenshot();
      itemInputRef.current = itemImgSrc;
      setItemImageSrc(itemImgSrc);
    }, [itemWebcamRef]);

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div id="item-label-image">
          <FormLabelDivControl labelText={t('itemImage')} />
        </div>
        <div
          id="item-webcam-controls"
          style={{ display: 'flex', justifyContent: 'space-evenly' }}
        >
          <div>
            <CameraComponent
              openCamera={openCamera}
              webCamRef={itemWebcamRef}
              cameraAltText="Item Camera"
            />
          </div>
          <div>
            <CameraImageScreenshot
              imageSrc={itemImageSrc}
              imageAltText="Item face"
            />
          </div>
        </div>
        <div>
          <button type="button" onClick={captureItem}>
            {t('takeItemImage')}
          </button>
        </div>
      </div>
    );
  }
);

const BillCameraAndImageComponent = React.memo(
  ({ openCamera, billWebCamRef, billInputRef }) => {
    // console.log('BillCameraAndImageComponent');

    const [billImageSrc, setBillImageSrc] = useState(null);
    const { t } = useTranslation();

    const captureBill = useCallback(() => {
      const billImgSrc = billWebCamRef.current.getScreenshot();
      billInputRef.current = billImgSrc;

      setBillImageSrc(billImgSrc);
    }, [billWebCamRef]);

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div id="bill-label-image">
          <FormLabelDivControl labelText={t('billImage')} />
        </div>
        <div
          id="bill-webcam-controls"
          style={{ display: 'flex', justifyContent: 'space-evenly' }}
        >
          <div>
            <CameraComponent
              openCamera={openCamera}
              webCamRef={billWebCamRef}
              cameraAltText="Bill Camera"
            />
          </div>
          <div>
            <CameraImageScreenshot
              imageSrc={billImageSrc}
              imageAltText="Bill face"
            />
          </div>
        </div>
        <div>
          <button type="button" onClick={captureBill}>
            {t('takeBillImage')}
          </button>
        </div>
      </div>
    );
  }
);

const WebCamComponent = ({ customerInputRef, itemInputRef, billInputRef }) => {
  const customerWebcamRef = useRef(null);
  const itemWebcamRef = useRef(null);
  const billWebCamRef = useRef(null);
  const { t } = useTranslation();
  const [cameraFacingMode, setCameraFacingMode] = useState('user');

  const [openCamera, setOpenCamera] = useState(false);

  const toggleCamera = () => {
    if (openCamera) {
      setOpenCamera(false);
    } else {
      setOpenCamera(true);
    }
  };
  const switchCamera = () => {
    console.log('switchCamera');
    if (cameraFacingMode === 'user') setCameraFacingMode('environment');
    else setCameraFacingMode('user');
  };
  return (
    <>
      <div id="camera-toggle-button">
        <button type="submit" onClick={toggleCamera}>
          {t('openCamera')}
        </button>
        <span style={{ marginLeft: 10 }}>
          Camera Status:
          {openCamera ? ' On' : ' Off'}
        </span>
        {/* <button type="submit" onClick={switchCamera}>
          {t('switchCamera')}
        </button> */}
      </div>

      <div id="camera-image-display" style={{ marginTop: 30 }}>
        {openCamera && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <CustomerCameraAndImageComponent
              openCamera={openCamera}
              customerWebcamRef={customerWebcamRef}
              customerInputRef={customerInputRef}
            />
            <ItemCameraAndImageComponent
              openCamera={openCamera}
              itemWebcamRef={itemWebcamRef}
              itemInputRef={itemInputRef}
            />
            <BillCameraAndImageComponent
              openCamera={openCamera}
              billWebCamRef={billWebCamRef}
              billInputRef={billInputRef}
            />
          </div>
        )}
      </div>
    </>
  );
};

function AddLoan() {
  console.info('AddLoan - Main');

  const { t, i18n } = useTranslation();

  const tempLoanSummaryContext = useContext(LoanSummaryContext);

  const { loanSummaryState } = tempLoanSummaryContext;
  const { globalLatestLoanSummary, globalTotalLoanCount } = loanSummaryState;

  console.log('globalTotalLoanCount');

  console.log(globalTotalLoanCount);

  const tempAppDisplayContext = useContext(AppDisplayContext);
  const { appDisplayState, appDisplayDispatch } = tempAppDisplayContext;
  const { selectedLanguage } = appDisplayState;

  // let BILLNUMBER = 'Receipt Number';
  // if (selectedLanguage === 'Tamil') {
  //   BILLNUMBER = t('billNumber');
  // }
  const [currentLoanItemsData, setCurrentLoanItemsData] = useState([]);
  const [currentLoanSumData, setCurrentLoanSumData] = useState({});

  const currentDateRefState = useRef(null);
  const receiptNumRefState = useRef(0);
  const bookNumRefState = useRef(0);
  const bookBillNumRefState = useRef(0);
  const customerNameRefState = useRef('');
  const customerNumRefState = useRef(0);
  const customerAddressRefState = useRef('');
  const customerImageRefState = useRef('');
  const loanItemsImageRefState = useRef('');
  const billImageRefState = useRef('');

  const saveLoanDetailsToDb = async () => {
    console.info('saveLoanDetailsToDb');

    try {
      const currentDate = currentDateRefState.current;
      const receiptNum = receiptNumRefState.current;
      const bookNum = bookNumRefState.current;
      const bookBillNum = bookBillNumRefState.current;
      const customerName = customerNameRefState.current;
      const customerNumber = customerNumRefState.current;
      const customerAddress = customerAddressRefState.current;
      const customerImage = customerImageRefState.current;
      const loanItemsImage = loanItemsImageRefState.current;
      const billImage = billImageRefState.current;

      console.log('currentLoanSumData');

      console.log(currentLoanSumData);

      // console.log('customerImagebase64');
      // console.log(customerImage);
      // console.log(loanItemsImage);
      // console.log(currentLoanItemsData);
      let totalLoanAmount = 0;
      const formattedLoanItemsData = currentLoanItemsData.map((loanItem) => {
        // actualLoanAmount: 100;
        // id: 1;
        // interestRate: 3;
        // itemMetal: 'gold';
        // itemType: 'ring';
        // maxItemValue: 100;
        // quantity: 1;

        const { itemMetal, itemType, weight, quantity, loanAmount } = loanItem;
        const formattedLoanItem = {
          metal: itemMetal,
          type: itemType,
          weight,
          quantity,
          purity: 916,
        };
        totalLoanAmount += loanAmount;
        return formattedLoanItem;
      });

      /** Calculate sum interest rate */
      let tempLoanInterestRate = 2;
      const tempLoanBillRate = 1;
      if (totalLoanAmount <= 10000) tempLoanInterestRate = 3;
      else tempLoanInterestRate = 2;

      const tempLoanInterestAmount =
        totalLoanAmount * (tempLoanInterestRate / 100);
      const tempBillRateAmount = totalLoanAmount * (tempLoanBillRate / 100);
      const totalInterestAmount = tempLoanInterestAmount + tempBillRateAmount;
      const tempSumTotalLoanAmount = totalLoanAmount - totalInterestAmount;

      // if (tempLoanInterestAmount > 0) setLoanInterestAmount(tempLoanInterestAmount);
      // if (totalLoanAmount > 0) setSumTotalLoanAmount(tempSumTotalLoanAmount);
      // if (tempBillRateAmount > 0) setBillRateAmount(tempBillRateAmount);
      /** Calculate sum interest rate */

      const newLoanDetails = {
        contactNumber: customerNumber,
        name: customerName,
        address: customerAddress,
        customerImage: customerImage || null,
        idType: null,
        idNumber: null,
        loanBillId: receiptNum,
        loanBookId: bookNum,
        loanBookBillId: bookBillNum,
        billImage: billImage || null,
        goldRate: 1,
        interestRate: tempLoanInterestRate,
        billRate: tempLoanBillRate,
        billRateCost: tempBillRateAmount,
        loanAmount: totalLoanAmount,
        status: 'new',
        loanItemsImage: loanItemsImage || null,
        loanItems: formattedLoanItemsData,
        loanDate: currentDate,
      };
      // const apiHostUrl = 'https://godigitalwithcmc.store';
      const addedLoanDetails = await axios.post(
        `${addLoanDetailsUrl}`,
        newLoanDetails
      );
      console.info(addedLoanDetails.data);
      if (addedLoanDetails.status === 200) {
        alert('Bill Added Successfully');
        // reset the add loan form
        window.location.reload();
      } else {
        console.info('Error on Save To DB');
        window.alert('Error on Save To DB');
      }
    } catch (error) {
      console.info('Error on Save To DB func : ', error);
    }

    // const addedLoanDetails = await axios.post('http://localhost:8080/api/v1/loanDetails/add', newLoanDetails);
    // console.log(addedLoanDetails.data);
  };

  const clearForm = () => {};
  return (
    <div id="add-loan" style={contentTop10Margin}>
      <h3 style={pageTitle}>Add Loan</h3>
      <div id="add-loan-content" style={content10Margin} className="container">
        <div id="date-content" className="row justify-content-end">
          <label className="col-lg-auto col-sm col-md"> Date:</label>
          <CurrentDateComponent currentDateRef={currentDateRefState} />
        </div>
        <div id="form-content" style={content10Margin}>
          <h5 style={sectionTitle}>{t('billNumberDetails')}</h5>
          <div id="bill-id-details" className="row justify-content-start">
            {/* <FormLabelInputControl labelText={t('receiptNumber')} inputRef={receiptNumRefState} /> */}
            {/* <label className="col-lg-auto col-sm col-md">
              {t('receiptNumber')}
            </label>
            <label className="col-lg-auto col-sm col-md">
              {globalTotalLoanCount + 1}
            </label> */}
            <FormLabelInputControl
              labelText={t('receiptNumber')}
              inputref={receiptNumRefState}
              defaultValue={globalTotalLoanCount + 1}
            />
            <div
              id="vertical-divider"
              style={{ width: 5, backgroundColor: 'gray' }}
            />

            <FormLabelInputControl
              labelText={t('bookNumber')}
              inputref={bookNumRefState}
              defaultValue={''}
            />
            <FormLabelInputControl
              labelText={t('bookBillNumber')}
              inputref={bookBillNumRefState}
              defaultValue={''}
            />
          </div>
          <DividerComponent />
          <h5 style={sectionTitle}>{t('customerDetails')}</h5>
          <div
            id="customer-id-details"
            style={contentTop10Margin}
            className="row justify-content-start"
          >
            <div>
              <FormLabelInputControl
                labelText={t('customerName')}
                inputref={customerNameRefState}
              />
              <FormLabelInputControl
                labelText={t('customerNumber')}
                inputref={customerNumRefState}
              />
            </div>
            <div>
              <FormLabelInputControl
                labelText={t('customerAddress')}
                inputref={customerAddressRefState}
              />
            </div>
          </div>
          <DividerComponent />
          <h5 style={sectionTitle}>{t('loanItemDetails')}</h5>
          <div id="loan-item-details" style={contentTop10Margin}>
            <AddLoanItemsTable
              currentLoanItemsData={currentLoanItemsData}
              addToCurrenLoanItemsData={setCurrentLoanItemsData}
              addToCurrentLoanSumData={setCurrentLoanSumData}
            />
          </div>
          <DividerComponent />
          <h5 style={sectionTitle}>{t('identificationDetails')}</h5>
          <div id="cam-recorder-control" style={contentTop10Margin}>
            <WebCamComponent
              customerInputRef={customerImageRefState}
              itemInputRef={loanItemsImageRefState}
              billInputRef={billImageRefState}
            />
          </div>
          <DividerComponent />
          <div
            id="cam-recorder-save"
            style={{ display: 'flex', justifyContent: 'space-evenly' }}
          >
            <button type="submit" onClick={saveLoanDetailsToDb}>
              {t('saveLoanDetails')}
            </button>
            <button type="submit" onClick={clearForm}>
              {t('clearLoanDetails')}
            </button>
          </div>
          <DividerComponent style={sectionTitle} />
        </div>
      </div>
    </div>
  );
}

AddLoan.propTypes = {};

export default AddLoan;
