const apiServerLocalHost = 'http://localhost:3000';
const apiServerRemoteHost = 'https://godigitalwithcmc.store';
const hostName = window.location.hostname;
let apiUrl = '';
let apiServerHost = '';
console.log(hostName);

if (hostName.includes('localhost')) apiServerHost = apiServerLocalHost;
else apiServerHost = apiServerRemoteHost;
// apiServerHost = apiServerRemoteHost;
apiUrl = `${apiServerHost}/api/v1`;
console.log(apiUrl);

const loanSummaryBaseUrl = `${apiUrl}/loanSummary`;
const getLatestLoanSummaryUrl = `${loanSummaryBaseUrl}/latest`;
const getLoanSummaryCountUrl = `${loanSummaryBaseUrl}/count`;
const getAllLoanSummaryUrl = `${loanSummaryBaseUrl}/all`;

const addLoanDetailsUrl = `${apiUrl}/loanDetails/add`;
const releaseLoanDetailsUrl = `${apiUrl}/loanDetails/release`;

export {
  addLoanDetailsUrl,
  getLatestLoanSummaryUrl,
  getLoanSummaryCountUrl,
  getAllLoanSummaryUrl,
  releaseLoanDetailsUrl,
};
