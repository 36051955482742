import React from 'react';
// import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import LoanMenuTab from './LoanMenuTab';
import { appLoanRoutes } from '../config/AppConstants';
// import AdminPage from './AdminPage';
import DashboardPage from './DashboardPage';
import AddLoan from './loan-menu-components/AddLoan';
import ReleaseLoan from './loan-menu-components/ReleaseLoan';

import LoginPage from './LoginPage';
import { loanPage, loanPageSideBar } from '../styles/LoanPageStyle';
import SearchPage from './SearchPage';

const {
  RELEASE_PATH,
  RENEW_PATH,
  ADD_PATH,
  SEARCH_PATH,
  PAYINTEREST_PATH,
  ASSIGN_BANK_PATH,
} = appLoanRoutes;
function LoanPage() {
  return (
    <Router>
      <div id="loan-page" style={loanPage}>
        <div id="loan-page-sidebar" style={loanPageSideBar}>
          <LoanMenuTab />
        </div>
        <div id="loan-page-content" style={{ flex: '90%' }}>
          <Route exact path={ADD_PATH}>
            <AddLoan />
          </Route>

          <Route path={ASSIGN_BANK_PATH}>
            <DashboardPage />
          </Route>
          <Route path={SEARCH_PATH}>
            <SearchPage />
          </Route>
          <Route path={PAYINTEREST_PATH}>
            <LoginPage />
          </Route>
          <Route path={RENEW_PATH}>
            <LoginPage />
          </Route>
          <Route path={RELEASE_PATH}>
            <ReleaseLoan />
          </Route>
        </div>
      </div>
    </Router>
  );
}

LoanPage.propTypes = {};

export default LoanPage;
