import { responsiveFontSizes } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

function SearchPage(props) {
  return <div>Search </div>;
}

export default SearchPage;
SearchPage.propTypes = {};
