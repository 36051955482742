import React from 'react';
import PropTypes from 'prop-types';

function AdminPage(props) {
  return <div>Admin Page</div>;
}

AdminPage.propTypes = {};

export default AdminPage;
