import React, { useContext, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'antd/dist/antd.css';
import { Table, Typography, Button } from 'antd';
import { LoanSummaryContext } from '../context/index';
import { DatePicker, Space } from 'antd';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;
const { RangePicker } = DatePicker;

const DashboardPage = () => {
  const { t } = useTranslation();

  const tempLoanSummaryContext = useContext(LoanSummaryContext);

  const { loanSummaryState } = tempLoanSummaryContext;
  const {
    globalLatestLoanSummary,
    globalTotalLoanCount,
    globalAllLoanSummary,
  } = loanSummaryState;

  const [displayLoanSummary, setDisplayLoanSummary] =
    useState(globalAllLoanSummary);

  const [isDateSelected, setIsDateSelected] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(moment());
  const [selectedEndDate, setSelectedEndDate] = useState(moment());

  const generateTableHeader = useCallback(() => {
    console.info('generateTableHeader - Home');
    const columns = [];

    columns.push({ title: 'Id', dataIndex: 'index', fixed: true });
    columns.push({ title: 'Bill Id', dataIndex: 'loanBillId', fixed: true });
    columns.push({ title: 'Book Id', dataIndex: 'loanBookId', fixed: true });
    columns.push({
      title: 'Book Bill Id',
      dataIndex: 'loanBookBillId',
      fixed: true,
    });
    columns.push({
      title: 'Loan Date',
      dataIndex: 'loanDate',
      fixed: true,
    });

    columns.push({
      title: 'Customer Id',
      dataIndex: 'customerId',
      fixed: true,
    });
    // columns.push({ title: 'Loan Item', field: '' });

    columns.push({
      title: 'Loan Amount',
      dataIndex: 'loanAmount',
      fixed: true,
    });
    columns.push({
      title: 'Interest Rate',
      dataIndex: 'interestRate',
      fixed: true,
    });

    return columns;
  });

  useEffect(() => {
    setDisplayLoanSummary(globalAllLoanSummary);
  }, [globalAllLoanSummary]);
  useEffect(() => {
    console.log(selectedStartDate);
    console.log(selectedEndDate);
    console.log(isDateSelected);

    if (isDateSelected) {
      let tempDisplayData = [];

      tempDisplayData =
        displayLoanSummary.length > 0 &&
        displayLoanSummary.filter((loanInfo) =>
          moment(moment(loanInfo.loanDate).format('YYYY-MM-DD')).isBetween(
            selectedStartDate,
            selectedEndDate,
            null,
            '[]'
          )
        );
      console.log('tempDisplayData');

      console.log(tempDisplayData);
      setDisplayLoanSummary(tempDisplayData);
      setIsDateSelected(false);
    }
  }, [isDateSelected]);

  const columns = generateTableHeader();

  const handleDateSelection = (selectedOption, selectedDateValues) => {
    console.log('handleDateSelection');
    console.log(selectedOption);
    console.log(selectedDateValues);
    setDisplayLoanSummary(globalAllLoanSummary);

    if (
      selectedDateValues !== null &&
      selectedDateValues !== undefined &&
      (selectedOption === 'CUSTOM'
        ? selectedDateValues[0].trim().length > 1 &&
          selectedDateValues[0].trim().length > 1
        : selectedDateValues.length > 0)
    ) {
      setIsDateSelected(true);

      switch (selectedOption) {
        case 'DATE':
          setSelectedStartDate(moment(selectedDateValues));
          setSelectedEndDate(moment(selectedDateValues));

          break;
        case 'MONTH':
          let months31 = ['01', '03', '05', '07', '08', '10', '12'];
          let months30 = ['04', '06', '09', '11'];
          let selectedMonthDetails = selectedDateValues.split('-');
          let selectedMonth,
            selectedYear,
            endDate = '';
          if (selectedMonthDetails.length > 1) {
            selectedYear = selectedMonthDetails[0];
            selectedMonth = selectedMonthDetails[1];
          }
          console.log(selectedMonth);

          if (selectedMonth === '02') {
            if (parseInt(selectedYear) % 4 === 0) endDate = '29';
            else endDate = '28';
          }
          if (months31.includes(selectedMonth)) endDate = '31';
          else if (months30.includes(selectedMonth)) endDate = '30';

          setSelectedStartDate(moment(`${selectedDateValues}-01`));
          setSelectedEndDate(moment(`${selectedDateValues}-${endDate}`));
          break;
        case 'YEAR':
          setSelectedStartDate(moment(`${selectedDateValues}-01-01`));
          setSelectedEndDate(moment(`${selectedDateValues}-12-31`));
          break;
        default:
          if (selectedDateValues.length > 0) {
            setSelectedStartDate(moment(selectedDateValues[0]));
            setSelectedEndDate(moment(selectedDateValues[1]));
          }
          break;
      }
    }
  };

  const clearDateRange = () => {
    setDisplayLoanSummary(globalAllLoanSummary);
  };
  //const data = displayLoanSummary();
  return (
    <>
      <Space direction="vertical" size={12} style={{ margin: 30 }}>
        <Space direction="horizontal" size={12}>
          <DatePicker
            bordered
            onChange={(date, str) => handleDateSelection('DATE', str)}
          />
          <DatePicker
            picker="month"
            bordered
            onChange={(date, str) => handleDateSelection('MONTH', str)}
          />
          <DatePicker
            picker="year"
            bordered
            onChange={(date, str) => handleDateSelection('YEAR', str)}
          />
          <RangePicker
            bordered
            onChange={(date, str) => handleDateSelection('CUSTOM', str)}
          />
          <Button type="primary" onClick={clearDateRange}>
            Clear
          </Button>
        </Space>
      </Space>
      <Table
        style={{ marginLeft: 50, marginTop: 30, marginRight: 50 }}
        columns={columns}
        dataSource={displayLoanSummary}
        bordered
        summary={(pageData) => {
          let totalLoanAmount = 0,
            grandTotalLoanAmount = 0;
          pageData.forEach(({ loanAmount }) => {
            totalLoanAmount += loanAmount;
          });
          displayLoanSummary.forEach(({ loanAmount }) => {
            grandTotalLoanAmount += loanAmount;
          });
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />

                <Table.Summary.Cell index={5}>
                  {t('totalAmount')}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  <Text type="danger" style={{}}>
                    {Intl.NumberFormat('en-IN').format(totalLoanAmount)}

                    {/* {totalLoanAmount} */}
                  </Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />

                <Table.Summary.Cell index={5}>
                  {t('grandTotalAmount')}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  <Text type="danger" style={{}}>
                    {/* {grandTotalLoanAmount} */}
                    {Intl.NumberFormat('en-IN').format(grandTotalLoanAmount)}
                  </Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />

      <br />
    </>
  );
};
// function DashboardPage(props) {
//   return <div>Dashboard</div>;
// }

DashboardPage.propTypes = {};

export default DashboardPage;
