export const header = {
  display: 'initial',
};

export const headerNavBar = {
  position: 'sticky',
  top: '0px',
  zIndex: 1000,
};

// .Header {
//   display: initial;
//   /* background-color: #131921;
//   display: flex;
//   align-items: center;
//   position: sticky;
//   top: 0;
//   z-index: 100; */
// }

// .Header__NavBar {
//   position: 'sticky';
//   top: '0px';
//   z-index: 1000;
// }
/* 
.header__logo {
  

  width: 40px;
  height: 40px;
  left: 10;
  object-fit: contain;

}

.header__nav {
  display: flex;
  justify-content: space-evenly;
}

.header__link {
  color: #ffffff;
  text-decoration: none;
}

.header__orgTitle {
  display: flex;
  flex: 1;
} */
