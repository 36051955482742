const initialLoanSummaryState = {
  globalTotalLoanCount: 0,
  globalLatestLoanSummary: [],
  globalAllLoanSummary: [],
  selectedLanguage: 'Tamil',
};

const initialAppDisplayState = {
  selectedLanguage: 'Tamil',
};

export default function reducer(state = initialLoanSummaryState, action) {
  const payloadData = action.payload;
  console.log(payloadData);

  const {
    globalTotalLoanCount,
    globalLatestLoanSummary,
    globalAllLoanSummary,
  } = state;

  switch (action.type) {
    case 'CREATE_NEW_LOAN':
      return {
        ...state,
        globalTotalLoanCount: globalTotalLoanCount + 1,
        globalLatestLoanSummary: globalLatestLoanSummary.concat(payloadData),
      };
    case 'LOAD_INITIAL_STATE_FROM_DB':
      if (
        Object.keys(payloadData).includes('dbTotalLoanCount') &&
        Object.keys(payloadData).includes('dbLatestLoanSummary')
      ) {
        let {
          dbTotalLoanCount,
          dbLatestLoanSummary,
          dbAllLoanSummary,
        } = payloadData;
        dbLatestLoanSummary = dbLatestLoanSummary.map((loanItem, index) => {
          return { ...loanItem, index: index + 1 };
        });
        dbAllLoanSummary = dbAllLoanSummary.map((loanItem, index) => {
          return { ...loanItem, index: index + 1 };
        });
        console.log('dbAllLoanSummary');

        console.log(dbAllLoanSummary.length);
        return {
          ...state,
          globalAllLoanSummary: dbAllLoanSummary,
          globalTotalLoanCount: dbTotalLoanCount,
          globalLatestLoanSummary: dbLatestLoanSummary,
        };
      }
      break;

    case 'SELECT_LANGUAGE':
      return {
        ...state,
        selectedLanguage: action.payload,
      };
    default:
      return initialLoanSummaryState;
  }
  return null;
}
