import React, { useContext } from 'react';
import { NavLink, Link, useHistory } from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
// import Logo from '../../public/logo512.png';
import { useTranslation } from 'react-i18next';
import { AppDisplayContext } from '../context/index';
import { appRoutes } from '../config/AppConstants';
import { header, headerNavBar } from '../styles/HeaderStyle';

const { DASHBOARD_PATH, HOME_PATH, LOAN_PATH, ADMIN_PATH, LOGIN_PATH } =
  appRoutes;
export default function Header() {
  const tempAppDisplayContext = useContext(AppDisplayContext);
  const { appDisplayState, appDisplayDispatch } = tempAppDisplayContext;
  const { selectedLanguage } = appDisplayState;

  let pastLocation = useHistory().location.pathname;
  let activeEventKey = 1;
  const { t, i18n } = useTranslation();

  if (pastLocation.includes(LOAN_PATH)) pastLocation = LOAN_PATH;
  switch (pastLocation) {
    case LOAN_PATH:
      activeEventKey = 2;
      break;
    case DASHBOARD_PATH:
      activeEventKey = 3;
      break;
    case ADMIN_PATH:
      activeEventKey = 4;
      break;
    case LOGIN_PATH:
      activeEventKey = 5;
      break;

    default:
      activeEventKey = 1;
      break;
  }

  const handleLanguageChange = (e) => {
    const currentSelectedLanguage = e.target.value;
    console.log(currentSelectedLanguage);
    i18n.changeLanguage(currentSelectedLanguage);

    appDisplayDispatch({
      type: 'SELECT_LANGUAGE',
      payload: currentSelectedLanguage,
    });
  };
  return (
    <div style={header}>
      <Navbar
        className="row"
        expand="md"
        bg="dark"
        variant="dark"
        style={headerNavBar}
      >
        <Navbar.Brand href="/" className="col">
          {t('shopTitle')}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="">
          <Nav
            fill
            className="mr-auto col-lg-9 col-sm-4 col-md-4"
            activeKey={activeEventKey}
          >
            <Nav.Link as={NavLink} to={HOME_PATH}>
              {/* {activeEventKey === 1 ? <span style={{ color: 'white' }}>Home</span> : 'Home'} */}{' '}
              {t('homeHeader')}
            </Nav.Link>

            <Nav.Link as={NavLink} to={LOAN_PATH}>
              {/* {activeEventKey === 2 ? <span style={{ color: 'white' }}>Loan</span> : 'Loan'} */}{' '}
              {t('loanHeader')}
            </Nav.Link>

            <Nav.Link as={NavLink} to={DASHBOARD_PATH}>
              {/* {activeEventKey === 3 ? <span style={{ color: 'white' }}>Dashboard</span> : 'Dashboard'} */}{' '}
              {t('dashboardHeader')}
            </Nav.Link>

            <Nav.Link as={NavLink} className="nav-link" to={ADMIN_PATH}>
              {/* {activeEventKey === 4 ? <span style={{ color: 'white' }}>Admin</span> : 'Admin'} */}{' '}
              Admin
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Text
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div>
            <input
              type="radio"
              name="select-tamil-lang"
              value="ta"
              checked={selectedLanguage === 'ta'}
              onChange={handleLanguageChange}
            />
            {/* {t('tamilLangTitle')} */}
            தமிழ்
          </div>
          <div style={{ marginLeft: 20 }}>
            <input
              type="radio"
              name="select-english-lang"
              value="en"
              checked={selectedLanguage === 'en'}
              onChange={handleLanguageChange}
            />
            English
          </div>
        </Navbar.Text>
        <Nav.Link as={Link} className="nav-link" to={LOGIN_PATH}>
          {activeEventKey === 5 ? (
            <span style={{ color: 'white' }}>Login</span>
          ) : (
            'Login'
          )}
        </Nav.Link>
      </Navbar>
    </div>
  );
}
