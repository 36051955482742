import React from 'react';
import { NavLink, Link, useHistory } from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import { appLoanRoutes } from '../config/AppConstants';
import {
  loanPageSideBarMenuTab,
  loanPageSideBarSelectedMenu,
} from '../styles/LoanPageStyle';

const {
  RELEASE_PATH,
  RENEW_PATH,
  ADD_PATH,
  SEARCH_PATH,
  PAYINTEREST_PATH,
  ASSIGN_BANK_PATH,
} = appLoanRoutes;

const LoanMenuTabContent = ({ menuName }) => {
  return <span style={loanPageSideBarSelectedMenu}>{menuName}</span>;
};
LoanMenuTabContent.propTypes = {
  menuName: PropTypes.string.isRequired,
};
function LoanMenuTab() {
  const pastLocation = useHistory().location.pathname;
  let activeEventKey = 1;
  // const { t, i18n } = useTranslation();

  console.info('pastLocation - ', pastLocation);
  switch (pastLocation) {
    case RELEASE_PATH:
      activeEventKey = 6;
      break;
    case ASSIGN_BANK_PATH:
      activeEventKey = 2;
      break;
    case SEARCH_PATH:
      activeEventKey = 3;
      break;
    case PAYINTEREST_PATH:
      activeEventKey = 4;
      break;
    case RENEW_PATH:
      activeEventKey = 5;
      break;

    default:
      console.info('switch past - ', pastLocation);
      activeEventKey = 1;
      break;
  }
  return (
    // <div style={{ display: 'flex', justifyContent: 'center' }}>
    <Navbar className="row">
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav" className="">
        <Nav
          fill
          className="mr-auto col-lg-12 col-sm-12 col-md-12"
          style={loanPageSideBarMenuTab}
          activeKey={activeEventKey}
        >
          <Nav.Link as={NavLink} className="col nav-link" to={ADD_PATH}>
            {/* {activeEventKey === 1 ? ( 
              <LoanMenuTabContent menuName="Add" />
             ) : (
              'Add'
            )}*/}
            Add
          </Nav.Link>
          <Nav.Link as={NavLink} className="col nav-link" to={ASSIGN_BANK_PATH}>
            {/* {activeEventKey === 2 ? (
              <LoanMenuTabContent menuName="Assign Bank" />
            ) : (
              'Assign Bank'
            )} */}
            Assign Bank
          </Nav.Link>
          <Nav.Link as={NavLink} className="col nav-link" to={SEARCH_PATH}>
            {/* {activeEventKey === 3 ? (
              <LoanMenuTabContent menuName="Search" />
            ) : (
              'Search'
            )} */}
            Search
          </Nav.Link>
          <Nav.Link as={NavLink} className="col nav-link" to={PAYINTEREST_PATH}>
            {/* {activeEventKey === 4 ? (
              <LoanMenuTabContent menuName="Pay Interest" />
            ) : (
              'Pay Interest'
            )} */}
            Pay Interest
          </Nav.Link>

          <Nav.Link as={NavLink} className="col nav-link" to={RENEW_PATH}>
            {/* {activeEventKey === 5 ? (
              <LoanMenuTabContent menuName="Renew" />
            ) : (
              'Renew'
            )} */}
            Renew
          </Nav.Link>
          <Nav.Link as={NavLink} className="col nav-link" to={RELEASE_PATH}>
            {/* {activeEventKey === 6 ? (
              <LoanMenuTabContent menuName="Release" />
            ) : (
              'Release'
            )} */}
            Release
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    // </div>
  );
}

LoanMenuTab.propTypes = {};

export default LoanMenuTab;
